import { createSlice } from '@reduxjs/toolkit'

export const modalSlice = createSlice({
  name: 'modals',
  initialState: {
    accountCheck: { display: false, state: 'form', details: {} },
    reunionTicket: {
      display: false, state: 'form', details: {
        // firstName: 'Jennifer',
        // email: 'tnwikpuinee@gmail.com',
        // surname: 'Nwips',
        // phone: '08108634160',
        // amount: '2,000',
        // isAlumnus: 'yes',
        // paymentReference: 'T9f43e30b-f65c-b03d-b53b-abud'
      }
    },
    reunionVendorTicket: { display: false, state: 'form', details: {} },
    // reunionVendorTicket: {display : true, state : 'success', details: {
    //   firstName: 'israel',
    //   email: 'izzyjnr@icloud.com',
    //   surname: 'Owate',
    //   phone: '08157305200',
    //   amount: '20,000',
    //   businessName: 'Munchies',
    //   paymentReference: 'T1eff0483-f4a5-d12c-a5f1-abud'
    // }},
    electionCandidateTicket: {
      display: false, state: 'form', details: {
        // firstName: 'israel',
        // lastName: "abiona",
        // position: "President",
        // email: 'izzyjnr@icloud.com',
        // phone: '08157305200',
        // amount: '20,000',
        // paymentReference: 'T1eff0483-f4a5-d12c-a5f1-abud'
      }
    },
  },
  reducers: {
    openModal: (state, action) => {
      switch (action.payload) {
        //  set all other modal state to false when one is set to open
        case 'accountCheck':
          state.accountCheck.display = true;
          state.reunionTicket.display = false;
          state.reunionVendorTicket.display = false;
          state.electionCandidateTicket.display = false;
          break;
        case 'reunionTicket':
          state.accountCheck.display = false;
          state.reunionTicket.display = true;
          state.reunionVendorTicket.display = false;
          state.electionCandidateTicket.display = false;
          break;
        case 'reunionVendorTicket':
          state.accountCheck.display = false;
          state.reunionTicket.display = false;
          state.electionCandidateTicket.display = false;
          state.reunionVendorTicket.display = true;
          break;
        case 'electionCandidateTicket':
          state.accountCheck.display = false;
          state.reunionTicket.display = false;
          state.electionCandidateTicket.display = true;
          state.reunionVendorTicket.display = false;
          break;
        default:
          state.accountCheck.display = true;
      }
    },
    setUpdateState: (state, action) => {
      // state.updateState = action.payload
    },
    closeModal: (state, action) => {
      state[action.payload]['display'] = false;
    }
  },
})

// Action creators are generated for each case reducer function
export const { openModal, closeModal, setUpdateState } = modalSlice.actions

export default modalSlice.reducer