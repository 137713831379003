import { Link } from "react-router-dom";
import logo from "../assets/img/abuad-alumni-logo.png";
import constitution from "../assets/docs/abuad_alumni_constitution.pdf";

function Footer() {

  return (
    <div className="px-4 md:px-12 bg-gray-100">

      <div className="sm:flex py-4 md:py-16">
        <div className="sm:w-full sm:px-8 flex flex-col md:flex-row md:space-x-48">
          <div className="flex flex-col">
            <span className="text-sm md:font-semibold text-primary-dark uppercase mt-4 md:mt-0 mb-2">Final year clearance</span>
            <span><a href="https://alumni.abuad.edu.ng/dev/register.html" className="text-gray-700 text-xs md:text-sm hover:text-primary">Final year clearance</a></span>
            <span><a target="_blank" rel="noreferrer" href={`https://wa.me/2349030009002?text=HI%2C%20I%20am%20having%20issues%20completing%20my%20clearance.%20my%20email%20address%20is%3A`} className="text-gray-700 text-xs md:text-sm hover:text-primary">Report clearance issue</a></span>
          </div>
          <div className="flex flex-col">
            <span className="text-sm md:font-semibold text-gray-700 uppercase mt-4 md:mt-0 mb-2">Alumni Directory</span>
            {/* <span><Link to="/signup" className="text-gray-700 text-xs md:text-sm hover:text-primary">Create account</Link></span> */}
            {/* <span><a href="https://www.platform.alumni.abuad.edu.ng/" className="text-gray-700  text-xs md:text-sm hover:text-primary">Sign in</a></span> */}
          </div>
          <div className="flex flex-col">
            <span className="text-sm md:font-semibold text-gray-700 uppercase mt-4 md:mt-0 mb-2">Association</span>
            <span><Link to="/about" className="text-gray-700  text-xs md:text-sm hover:text-primary">About us </Link></span>
            <span><a href={constitution} download className="text-gray-700  text-xs md:text-sm hover:text-primary">Our consititution</a></span>
            <span><Link to="/about" className="text-gray-700  text-xs md:text-sm hover:text-primary">Executives</Link></span>
            <span><Link to="/election" className="text-gray-700  text-xs md:text-sm hover:text-primary">Election</Link></span>
          </div>
        </div>
      </div>

      {/* attribution */}
      <div className="flex items-center space-x-2 border-t py-4 md:py-8 lg:border-t border-gray-200">
        <img className="h-10 w-10" src={logo} alt="abuad alumni logo"></img>
        <div className="text-xs text-gray-700 space-y-1">
          <p>ABUAD ALUMNI ASSOCIATION</p>
          <p>KM. 8.5, Afe Babalola way, Ado-Ekiti, Ekiti state, Nigeria. P.M.B. 5454 Ado-Ekiti.</p>
          <p>© Abuad alumni association 2021. All rights reserved.</p>
        </div>
      </div>


    </div>
  );
}

export default Footer;
