
export async function postRequest(body, endpoint = '', key = process.env.REACT_APP_ABUAD_API_ADMIN_KEY) {

  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      },
      body: JSON.stringify(body)
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response

  } catch (err) {
    return {
      "status": "false"
    };
  }
}

export async function findAccount(endpoint = '', key = process.env.REACT_APP_ABUAD_API_USER_KEY) {

  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/${endpoint}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      },
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response

  } catch (err) {
    return {
      "status": "false"
    };
  }
}

export async function sendLoginDetails(userId = '', key = process.env.REACT_APP_ABUAD_API_ADMIN_KEY) {
  console.log(userId);
  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/alumni?verify=${userId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      },
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response

  } catch (err) {
    return {
      status: false
    };
  }
}

export async function getData(query) {
  const adminKey = process.env.REACT_APP_ABUAD_API_ADMIN_KEY

  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        'Authorization': adminKey,
      },
    });
    const response = await res.json();

    // return courses array if the response status is true
    // else throw an error
    if (response?.status === true) {
      return response;
    } else {
      return [];
    }
  } catch (err) {
    return [];
  }
}

export async function getVoterId(request) {
  const endpoint = 'election-voter-id-send';
  // const key = process.env.REACT_APP_ABUAD_API_ADMIN_KEY;
  const key = process.env.REACT_APP_ABUAD_API_TEST_ADMIN_KEY;
  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      },
      body: JSON.stringify(request)
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response;

  } catch (err) {
    return {
      "status": false
    };
  }
}

export async function verifyVoterId(id) {
  const endpoint = 'election-voter';
  // const key = process.env.REACT_APP_ABUAD_API_ADMIN_KEY;
  const key = process.env.REACT_APP_ABUAD_API_TEST_ADMIN_KEY;
  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/${endpoint}/${id}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      }
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response;

  } catch (err) {
    return {
      "status": false
    };
  }
}

export async function sendVotes(request) {
  const endpoint = 'election-vote';
  // const key = process.env.REACT_APP_ABUAD_API_ADMIN_KEY;
  const key = process.env.REACT_APP_ABUAD_API_TEST_ADMIN_KEY;
  try {
    const res = await fetch(`${process.env.REACT_APP_ALUMNI_API}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': key,
      },
      body: JSON.stringify(request)
    })
    const response = await res.json();
    // return courses array if the response status is true
    // else throw an error
    return response;

  } catch (err) {
    return {
      "status": false
    };
  }
}