import Footer from "../Components/Footer";
import Hero from "../Components/Section_hero";
import TopBar from "../Components/TopBar";
import { FaPhoneSquareAlt } from "react-icons/fa";
import { SiWhatsapp } from "react-icons/si";



function Contact(){
  return(
    <div>
      <TopBar/>
      <Hero title={'Contact Us'}/>

      <div className="px-4 py-8 md:py-16 md:px-20 space-y-4 bg-gray-50">

        <div className="space-y-8">
          <h2 className="text-base max-w-xs md:max-w-lg md:text-2xl text-primary-dark font-extrabold">We are located on first floor Talent Discovery Center Afe Babalola University</h2>
          
          <div className="space-y-4">

            <div className="md:max-w-xs bg-white shadow p-4 rounded text-gray-700 ">
              <h3 className="font-bold mb-1 text-base">General Inquiriees</h3>
              <p className="text-sm text-gray-700 flex items-center space-x-1">
                <span><FaPhoneSquareAlt/></span>
                <span>08131223796</span>
              </p>
            </div>

            <div className="md:max-w-xs bg-white shadow p-4 rounded  text-gray-700 space-y-1">
              <h3 className="font-bold mb-1 text-base">Clearance and technical issues</h3>

              <p className="text-sm">Click the button below to start a chat with technical support </p>

              <p className="text-sm text-gray-700">
                <a href="https://wa.me/2349030009002?text=HI%2C%20I%20am%20having%20issues%20completing%20my%20clearance.%20my%20email%20address%20is%3A" className=" mt-1 text-white inline-flex items-center space-x-1 font-semibold px-4 py-2 rounded background bg-green-400 hover:bg-green-500" alt="start whatsapp chat">
                 <span><SiWhatsapp/></span>
                 <span>Start chat</span>
                </a>
              </p>

            </div>

          </div>

        </div>


      </div>
      <Footer/>
    </div>
  );
}




export default Contact;