import { Link } from "react-router-dom";
import Footer from "../Components/Footer";
import Hero from "../Components/Section_hero";
import TopBar from "../Components/TopBar";



function Services(){
  return(
    <div>
      <TopBar/>
      <Hero title={'Services'}/>

      <div className="bg-gray-50 py-10">

        <div className="p-4 md:px-20 grid md:grid-cols-3 gap-4">

          <Link to="/transcript" className="bg-white shadow p-4 rounded hover:shadow-lg text-primary-dark hover:text-primary transition duration-300">
            <h3 className=" font-bold mb-1">Transcript request</h3>
            <p className="text-sm text-gray-700">Click here to find out how to request for your request for your transcript, or how to send your transcript directly to an institution</p>
          </Link>

          <Link to="/transcript" className="bg-white shadow p-4 rounded hover:shadow-lg text-primary-dark hover:text-primary transition duration-300">
            <h3 className=" font-bold mb-1">Credential Evaluation</h3>
            <p className="text-sm text-gray-700">Click here to find out how to request for evaluations of your credentials.</p>
          </Link>

          <div className="bg-white shadow p-4 rounded text-primary-dark hover:shadow-lg hover:text-primary transition duration-300">
            <h3 className="font-bold mb-1">Scholarship</h3>
            <p className="text-sm text-gray-700">We offer scholarships to deserving candidates. Folllow our social media accounts for updates</p>
          </div>

          <div className="bg-white shadow p-4 rounded text-primary-dark hover:shadow-lg hover:text-primary transition duration-300">
            <h3 className="font-bold mb-1">Business Sponsorship</h3>
            <p className="text-sm text-gray-700">We support our entrepreneurs via several initiatives. Follow our social media accounts to get up to date information about our business sponsorship offerings</p>
          </div>

          <Link to="/signin" className="bg-white shadow p-4 rounded hover:shadow-lg text-primary-dark hover:text-primary transition duration-300">
            <h3 className="font-bold mb-1">Alumni Directory</h3>
            <p className="text-sm text-gray-700">Search through the Alumni Directory to connect to your old collegues</p>
          </Link>
        </div>

      </div>
      <Footer/>
    </div>
  )
}

export default Services;