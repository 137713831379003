import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getData, postRequest } from "../services/data/requests";
import { FiLoader } from 'react-icons/fi';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { AiOutlineWarning } from 'react-icons/ai';


function VerifyPayment(){
const location = useLocation();
const url = location.pathname;

const [state, setState] = useState({display: 'loading', details: {} })

var array = url.split("/");
const reference = array[2];
const paymentReference= reference.split("=")[1];


function handleCheckIn(){
  const reqBody = {
    reference : state.details.reference,
    cleared: "true"
  }

  Promise.resolve(postRequest(reqBody, 'payment-update'))
  .then(function(response){
    if(response.status === true){
      setState({display: 'success', details: {...response.data} })
    }
  })
}






useEffect(() =>{
  Promise.resolve( getData(`payment/${paymentReference}`))
  .then(function(response){
    console.log(response);
    if(response.status === true){
      setState({display: 'success', details:{...response.data}})
    }else{
      setState({display: 'fail', details:{}})
    }
  })
},[paymentReference])



  if(state.display === 'loading'){
    return(
      <div>
        <div className="flex items-center justify-center h-40 md:h-56 bg-hero md:bg-hero-lg bg-cover border border-gray-200">
          <h1 className="text-2xl md:text-3xl text-primary-dark text-center font-semibold max-w-xs md:max-w-md">Ticket verification</h1>
        </div>
        <div className="mt-14 flex items-center justify-center ">
          <div className="flex flex-col items-center space-y-1">
            <span className="text-primary text-4xl"><FiLoader/></span>
            <p className="text-xl text-primary">Loading...</p>
          </div>
         
        </div>
  
      </div>
    )
  }

  if(state.display === 'success'){
    return(
      <div>
        <div className="flex items-center justify-center h-40 md:h-56 bg-hero md:bg-hero-lg bg-cover border border-gray-200">
          <h1 className="text-2xl md:text-3xl text-primary-dark text-center font-semibold max-w-xs md:max-w-md">Ticket verification</h1>
        </div>

        <div className=" mt-14 flex items-center justify-center ">
          <div className="space-y-2 text-gray-700">
            <div className="flex justify-center">
              <span className="text-green-200 text-6xl"><BsFillPersonCheckFill/></span>
            </div>
            <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
              <span>NAME</span>
              <span>{state.details.customer['firstName']} {state.details.customer['lastName']}</span>
            </p>
            <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
              <span>PHONE</span>
              <span>{state.details.customer.phone}</span>
            </p>
            <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
              <span>EMAIL</span>
              <span>{state.details.customer['email']}</span>
            </p>
            <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
              <span>AMOUNT PAID</span>
              <span>{state.details['amount']} NGN</span>
            </p>
            <p className="grid grid-cols-2 bg-gray-100 px-4 py-1 rounded text-sm">
              <span>TYPE</span>
              <span>{state.details['purpose']}</span>
            </p>

              {state.details.cleared === 'true' ? <p className="flex justify-center bg-red-600 px-4 py-3 rounded text-sm">
                <span className='text-center font-bold text-white'>CHECKED IN</span></p> : null }
              

          </div>  
        </div>

          {
            state.details.cleared === 'false' ?
            <div className="flex mt-20 mx-4 max-w-md justify-center bg-primary px-4 py-3 rounded text-sm">
              <button onClick={handleCheckIn} className='text-center font-bold text-white'>CHECK TICKET IN</button>
            </div>
            :
            null

          }
            

      </div>
    )
  }

  if(state.display === 'fail'){
    return(
      <div>
        <div className="flex items-center justify-center h-40 md:h-56 bg-hero md:bg-hero-lg bg-cover border border-gray-200">
          <h1 className="text-2xl md:text-3xl text-primary-dark text-center font-semibold max-w-xs md:max-w-md">Ticket verification</h1>
        </div>
        <div className="mt-14 flex items-center justify-center ">
          <div className="flex flex-col items-center space-y-1">
            <span className="text-red-500 text-4xl"><AiOutlineWarning/></span>
            <p className="text-red-500 text-xl">NO PAYMENT FOUND</p>
          </div>
        </div>
  
      </div>
    )
  }
  else{

      return(
        <div>
          <div className="flex items-center justify-center h-40 md:h-56 bg-hero md:bg-hero-lg bg-cover border border-gray-200">
          <h1 className="text-2xl md:text-3xl text-primary-dark text-center font-semibold max-w-xs md:max-w-md">Ticket verification</h1>
        </div>
          <div className="mt-14 flex items-center justify-center ">
            <div className="flex flex-col items-center space-y-1">
              <p className="text-xl text-primary">Please close page and scan again</p>
            </div>
           
          </div>
    
        </div>
      )
  }

}

export default VerifyPayment;