/**
 * The colleges listed here have to be exactly thesame with those stored in the api database
 */
const colleges = [
  'Sciences',
  'Law',
  'Engineering',
  'Social and Management Sciences',
  'Medicine and Health Sciences',
  'Agriculture',
  'Arts and Humanities',
]

export default colleges;