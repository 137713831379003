import TopBar from "../Components/TopBar";
import Footer from "../Components/Footer";
import { MdEmail } from "react-icons/md";
import { FaLinkedin } from "react-icons/fa";

import president from "../assets/img/president_img.jpg";
import vp1 from "../assets/img/vp1_img.jpg";
import vp2 from "../assets/img/vp2_img.jpg";
import vp3 from "../assets/img/vp3_img.jpg";
// import secGen from "../assets/img/gen_sec_img.jpg";
// import finSec from "../assets/img/fin_sec_img.jpg";
import welfare from "../assets/img/welfare_img.jpg";
// import pro from "../assets/img/pro_img.jpg";
// import legal from "../assets/img/legal_img.jpg";
import auditor from "../assets/img/auditor_img.jpg";
import assSec from "../assets/img/ass_sec_gen_img.jpg";
import anon from "../assets/img/anon_img.png";
import Hero from "../Components/Section_hero";

function About() {
  return (
    <div className="bg-gray-50">
      <TopBar />
      <Hero title={"We are connecting all ABUAD graduates"} />

      <div className="py-8 px-4 space-y-16">
        {/* President message */}
        <div className=" shadow-lg mx-4 mt-8 py-8 bg-white px-4 rounded-md space-y-3  md:max-w-3xl md:mx-auto md:px-16">
          <img
            className="h-36 w-auto-36 rounded-full mx-auto"
            src={president}
            alt="abuad alumni president"
          />
          <h3 className="text-xl md:text-center font-semibold text-primary-dark">
            Welcome back to your Alma mater
          </h3>

          <div className="space-y-4 text-gray-700">
            <p className="text-sm">
              As an active and observant member of the ABUAD Alumni body, I am
              drawn to the outstanding qualities each Alumnae can offer for the
              betterment of the Association. Over the years, I have seen the
              determination, perseverance and resilience our colleagues have put
              in their careers, making them standout amongst their peers, both
              nationally and internationally.
            </p>
            <p className="text-sm">
              I am aware that these human resources should be strategically
              harnessed under the service of my leadership, if we must fill the
              current void and build a formidable and industrious Association of
              global leaders.
            </p>

            <div className="text-xs">
              <p>Long Live ABUAD</p>
              <p>Dr Ilugbusi Segun</p>
              <p>ABUAD Alumni President</p>
            </div>
          </div>
        </div>

        {/* Excos */}
        <div className="space-y-8 md:space-y-16 px-4 md:px-20">
          <div className="md:text-center">
            <h2 className="text-primary-dark text-2xl md:text-3xl font-semibold">
              Executives
            </h2>
            <p className="text-gray-700 text-base">Our executives</p>
          </div>

          {/* Exco images */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-y-6">
            {/* president */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={president}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Segun Ilugbusi</p>
              <p className="text-xs text-primary">President</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* vice president 1 */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={vp1}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Fisayo Bankole</p>
              <p className="text-xs text-primary">Vice President 1</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* vice president 2 */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={vp2}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Kehinde Adebisi</p>
              <p className="text-xs text-primary">Vice President II</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* vice president 3 */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={vp3}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Ilori Cordelia</p>
              <p className="text-xs text-primary">Vice President III</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={assSec}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Bolanle Alufa</p>
              <p className="text-xs text-primary">Secretary General</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Financial secretary */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={
                  "https://hego3rip.com/api/abuad-alumni/avatar/election/2022/financialsecretary/0ccd8bc417ae5ef941ac59af7039a6cd.jpeg"
                }
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Mark Achukwu</p>
              <p className="text-xs text-primary">Financial Secretary</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Welfare officer */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={welfare}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Oluwatomisin Ige</p>
              <p className="text-xs text-primary">Social Welfare Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Public relations officer */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={
                  "https://hego3rip.com/api/abuad-alumni/avatar/election/2022/publicrelationsofficer/e567670801b1f210c92044b1e16ad68b.jpeg"
                }
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Daniel Musa</p>
              <p className="text-xs text-primary">PRO</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Treasurer*/}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={
                  "https://hego3rip.com/api/abuad-alumni/avatar/election/2022/treasurer/73dec59edde0c1f752dbf43e4055b0ca.jpeg"
                }
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Kehinde Olowookere</p>
              <p className="text-xs text-primary">Treasurer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Legal Advisor */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={
                  "https://hego3rip.com/api/abuad-alumni/avatar/election/2022/legaladviser/30a96cc41a87df1498fa0f21cc97d801.jpeg"
                }
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Alex Oche</p>
              <p className="text-xs text-primary">legal Advisor</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Internal Auditor */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={auditor}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Oghenekowho Money</p>
              <p className="text-xs text-primary">Internal auditor</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src="https://hego3rip.com/api/abuad-alumni/avatar/election/2022/assistantsecretarygeneral/171dcb8b09943315a8ace0f2ea71d85c.jpeg"
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Femi Isola</p>
              <p className="text-xs text-primary">Asst Secretary General</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>
          </div>

          <div className="md:text-center">
            <h2 className="text-primary-dark text-2xl md:text-3xl font-semibold">
              Piooner Executives
            </h2>
            <p className="text-gray-700 text-base">First Executives</p>
          </div>

          {/* Piooner Exco images */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-y-6">
            {/* president */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Tope Sobajo</p>
              <p className="text-xs text-primary">President</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* vice president 1 */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Ayodeji Osagie</p>
              <p className="text-xs text-primary">Vice president</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* vice president 2 */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Odebunmi Oluwabukola</p>
              <p className="text-xs text-primary">Vice president North</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* vice president 3 */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Paul Ubah</p>
              <p className="text-xs text-primary">Vice president, South East</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Damilola Alofe</p>
              <p className="text-xs text-primary">General Secretary North</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Financial secretary */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Ify-love Ikwekwere</p>
              <p className="text-xs text-primary">Financial Secretary</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Welfare officer */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Adesola Akomolafe</p>
              <p className="text-xs text-primary">Social Welfare Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Public relations officer */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Aderonke Makanju</p>
              <p className="text-xs text-primary">Financial Secretary</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Legal Advisor */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Fiyinfoluwa Oluwatuyi</p>
              <p className="text-xs text-primary">Welfare Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Internal Auditor */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Olajide Omosebi</p>
              <p className="text-xs text-primary">Legal Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Faisal Bashir</p>
              <p className="text-xs text-primary">PRO</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Ebunoluwa Ogunyebi</p>
              <p className="text-xs text-primary">Liason Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Olamide Popoola</p>
              <p className="text-xs text-primary">Liason Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Tayo Fabunmi</p>
              <p className="text-xs text-primary">Liason Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Mary Adeyemo</p>
              <p className="text-xs text-primary">Liason Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Oluwaseun Adelugba</p>
              <p className="text-xs text-primary">Liason Officer</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Linus Enye</p>
              <p className="text-xs text-primary">Staff Adviser</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>

            {/* Assistant secretary general */}
            <div className="text-center text-gray-700">
              <img
                className="h-28 w-28 md:h-36 md:w-36 mx-auto rounded-full"
                src={anon}
                alt="abuad alumni executive"
              ></img>
              <p className="text-sm">Layi Ajisafe</p>
              <p className="text-xs text-primary">Staff Adviser</p>
              <div className="flex space-x-2 text-base justify-center">
                <span>
                  <MdEmail />
                </span>
                <span>
                  <FaLinkedin />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default About;
