import { useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/abuad-alumni-logo.png";
import { GiHamburgerMenu } from "react-icons/gi";
import { SiWhatsapp } from "react-icons/si";

function TopBar() {
  useEffect(() => {
    // Grab HTML Elements
    const btn = document.querySelector("button.mobile-menu-button");
    const menu = document.querySelector(".mobile-menu");

    // Add Event Listeners
    btn.addEventListener("click", (e) => {
      e.stopPropagation();
      menu.classList.toggle("hidden");
    });
  });

  return (
    <nav className="bg-white shadow-lg">
      <div className="mx-auto px-4 md:px-20 shadow md:shadow-none">
        <div className="flex justify-between">
          <div className="flex space-x-7">
            <div>
              <Link to="/" className="flex items-center py-4 px-2">
                <img
                  src={logo}
                  alt="abuad alumni logo"
                  className="h-8 w-8 mr-2"
                />
                <span className="font-medium text-primary-dark text-lg">
                  ABUAD ALUMNI
                </span>
              </Link>
            </div>

            <div className="hidden md:flex items-center space-x-1">
              <Link
                to="/"
                className="py-4 px-2 text-primary-dark transition duration-300 text-sm font-normal hover:text-primary"
              >
                Home
              </Link>
              <Link
                to="/services"
                className="py-4 px-2 text-primary-dark transition duration-300 text-sm font-normal hover:text-primary"
              >
                Services
              </Link>
              <Link
                to="/about"
                className="py-4 px-2 text-primary-dark transition duration-300 text-sm font-normal hover:text-primary"
              >
                About
              </Link>
              <Link
                to="/contact"
                className="py-4 px-2 text-primary-dark transition duration-300 text-sm font-normal hover:text-primary"
              >
                Contact
              </Link>
            </div>
          </div>

          {/* COmmented this out because it is confusing people trying to register...they think you have to sign in or create an account to register */}
          <div className="hidden md:flex items-center space-x-3 ">
            {/* <a
              href="http://www.platform.alumni.abuad.edu.ng"
              className="py-4 px-2 text-primary-dark transition duration-300 text-sm font-semibold hover:text-primary"
            >
              Sign in
            </a>
            <Link
              to="/signup"
              className="py-2 px-6 text-sm font-normal text-white bg-primary-dark rounded hover:bg-primary duration-200"
            >
              Create account (old students)
            </Link> */}
          </div>
          <div className="md:hidden flex items-center text-2xl">
            <button className="outline-none mobile-menu-button">
              <GiHamburgerMenu />
            </button>
          </div>
        </div>
      </div>

      {/* mobile */}
      <div className="hidden mobile-menu">
        <ul className="divide-y divide-gray-100">
          <li>
            <Link to="/" className="block text-gray-700 text-sm px-2 py-4">
              Home
            </Link>
          </li>
          <li>
            <Link
              to="/services"
              className="block text-gray-700 text-sm px-2 py-4 transition duration-200"
            >
              Services
            </Link>
          </li>
          <li>
            <Link
              to="/about"
              className="block text-gray-700 text-sm px-2 py-4 transition duration-200"
            >
              About
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="block text-gray-700 text-sm px-2 py-4 transition duration-200"
            >
              Contact
            </Link>
          </li>

          {/* <li>
            <a
              href="http://www.platform.alumni.abuad.edu.ng"
              className="block font-boldtext-gray-700 text-sm px-2 py-4 transition duration-200"
            >
              Sign in
            </a>
          </li> */}

          <li>
            <a
              href="https://wa.me/2349030009002?text=HI%2C%20I%20am%20having%20technical%20issues%20on%20the%20abuad%20alumni%20website."
              className=" mt-1 text-white mx-4 flex items-center space-x-1 font-semibold px-4 py-2 rounded background bg-green-400 hover:bg-green-500"
              alt="start whatsapp chat"
            >
              <span>
                <SiWhatsapp />
              </span>
              <span>24/7 Technical support</span>
            </a>
          </li>

          <li className="px-4 py-3">
            {/* <Link to="/signup" className="text-center block py-3 px-6 text-sm font-normal text-white bg-primary-dark rounded hover:bg-primary duration-200">Create account (old graduates)</Link> */}
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default TopBar;
