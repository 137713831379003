import { createSlice } from '@reduxjs/toolkit'

export const voteFlowSlice = createSlice({
  name: 'voteFlow',
  initialState: {
    display: { view: 'send-code', voterMask: '', voterId: "" },
    details: {},
  },
  reducers: {
    changeView: (state, action) => {
      state.display.view = action.payload
    },
    changeVoterMask: (state, action) => {
      state.display.voterMask = action.payload
    },
    changeVoterId: (state, action) => {
      state.display.voterId = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { changeView, changeVoterMask, changeVoterId } = voteFlowSlice.actions

export default voteFlowSlice.reducer