

function Hero(props){
  return(
    <div className="flex items-center justify-center h-40 md:h-56 bg-hero md:bg-hero-lg bg-cover border border-gray-200">
      <h1 className="text-2xl md:text-3xl text-primary-dark text-center font-semibold max-w-xs md:max-w-md">{props.title}</h1>
    </div>
  );
}

export default Hero;