export function hideemail(target) {
  var email = target //anas.behhari@gmail.com
  var hiddenEmail = "";
  var i;
  for (i = 0; i < email.length; i++) {
    if (i > 2 && i< email.indexOf("@") ) {
      hiddenEmail += "*";
    } else {
      hiddenEmail += email[i];
    }
  }
  return hiddenEmail;
}

export function hideText(target) {
  var text = target //anas.behhari@gmail.com
  var hiddenText = "";
  var i;
  for (i = 0; i < text.length; i++) {
    if (i > 2 && i< (text.length - 4) ) {
      hiddenText += "*";
    } else {
      hiddenText += text[i];
    }
  }
  return hiddenText;
}

export function generateTransactionId(platform){

  let dt = new Date().getTime();
  let pattern;
  switch(platform){
    case 'paystack':
      pattern = 'Txxxxxxxx-pxxx-xxxx-yxxx';
      break;
    case 'flutterwave':
      pattern = 'Txxxxxxxx-fxxx-xxxx-yxxx';
      break;
    case 'pos':
      pattern = 'Txxxxxxxx-sxxx-xxxx-yxxx';
      break;
    default:
    pattern = 'Txxxxxxxx-sxxx-xxxx-yxxx';
  }

  return `${pattern.replace(/[xy]/g, function(c) {
    let r = (dt + Math.random()*16)%16 | 0;
    dt = Math.floor(dt/16);
    return (c==='x' ? r :((r&0x3)|(0x8))).toString(16);
  })}-abud`;

}
