import TopBar from "../Components/TopBar";
import SignupForm from "../Components/Signup_form";
import Footer from "../Components/Footer";
// import { useState } from "react";

function Signup(){
  
  // const[buttonState, setButtonState] = useState('default');

  
  return(
    <div>
      <TopBar/>
      <div className="">
        {/* left */}
        <div className="bg-school-boy py-16 px-4 md:py-20 bg-cover bg-no-repeat">

          <div className="text-center text-white drop-shadow-sm">
            <p className="text-base">Signup to our platform</p>
            <p className="text-lg font-bold">Exclusive to Afe Babalola University graduates</p>
            <p className="text-sm">Connect with your old classmates, and enjoy other services we will be adding in the future</p>
          </div>

        </div>

        {/* right */}
        <SignupForm/>

      </div>
      <Footer/>
    </div>
  )
}

export default Signup;